<template>
  <div>
    <div class="overmask" :style="returnaCor('alphaLogin', false, true)"></div>

    <!-- <div class="container" id="opt-ini-whatsapp">
      <loading :show="loading"></loading>
      <erro-conexao :logo="logo" :erroMensagem="errorConexao"></erro-conexao>

      <div v-if="!errorConexao" class="content">
        <img src="./../../assets/icones/icon-whatsapp.svg" />
        <h1>Receba notificações pelo WhatsApp</h1>
        <p>
          Com seu aceite, nós podemos enviar comunicações e informações
          importantes para você via WhatsApp
        </p>
      </div>

      <a
        class="btn btn-block mt-5 mb-3"
        :style="returnaCor('botaoLogin', true, false)"
        @click="postOptInWhatsapp()"
        >Sim, quero receber comunicação pelo WhatsApp
      </a>

      <b-button
        variant="link"
        class="text-white recusa"
        @click="postSendCodigoVerificao()"
        >Não, continuar recebendo via SMS/E-mail</b-button
      >
    </div> -->

    <!-- Provisorio enquanto o whatpsap está fora-->
    <div class="container" id="opt-ini-whatsapp">
      <loading :show="loading"></loading>
      <erro-conexao :logo="logo" :erroMensagem="errorConexao"></erro-conexao>

      <div v-if="!errorConexao" class="content">

        <h1>Receba notificações pelo Email/SMS</h1>
        <p>
          Com seu aceite, nós podemos enviar comunicações e informações
          importantes para você via WhatsApp
        </p>
      </div>

      <a
        class="btn btn-block mt-5 mb-3"
        :style="returnaCor('botaoLogin', true, false)"
        @click="postSendCodigoVerificao()"
        >Sim, receber comunicação  via E-mail/SMS
      </a>

     
    </div>
  <!-- Provisorio enquanto o whatpsap está fora-->

  </div>
</template>

<script>
import service from './../../services';
import utils from './../../services/utils';
import { buscaDadosEmpresa } from './../../services/dados-empresa';
import { enviarCodigoNovamente } from './../../services/enviar-codigo-novamente';
import { returnCor } from './../../services/return-cor';

import GLOBAL from '../../shared/global';
import erroConexao from './../../shared/erro-conexao.vue';
import loading from './../../shared/loading.vue';

export default {
  name: 'opt-ini-whatsapp',
  data() {
    return {
      loading: true,
      errorConexao: '',
      logo: GLOBAL.LOGO,
      idProspect: null,
      dadosRes: {},
      temaCor: {
        botaoLogin: '#000000',
        alphaLogin: '#000000',
        botaoFormularios: '#000000',
      },
    };
  },
  methods: {
    async buscaDadosEmpresa() {
      const result = await buscaDadosEmpresa();

      this.logo = result.logo;
      this.temaCor = result.temaCor;
      this.idProspect = result.dadosRes.id_prospect;
      this.dadosRes = result.dadosRes;

      this.loading = false;
    },
    returnaCor(chave, comSombra, backgroundTransparent) {
      return returnCor(this.temaCor, chave, comSombra, backgroundTransparent);
    },
    postOptInWhatsapp() {
      this.loading = true;

      const data = {
        idProspect: this.idProspect,
      };

      service
        .post('Login', 'opt-in-whatsapp', data)
        .then((res) => {
          this.loading = false;
          const { redirect } = res.data;

          if (redirect) {
            this.updateOptInWhatsApp();

            this.$router.push({
              path: redirect,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          utils.showToast(this.$bvToast, e, 'danger');
        });
    },
    updateOptInWhatsApp() {
      this.dadosRes.optInWhatsapp = true;
      localStorage.setItem('dadosRes', JSON.stringify(this.dadosRes));
    },
    async postSendCodigoVerificao() {
      this.loading = true;
      try {
        const { redirect } = await enviarCodigoNovamente(
          { dadosRes: this.dadosRes },
          this.$bvToast,
        );

        if (redirect) {
          this.$router.push({
            path: redirect,
          });
        }
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.buscaDadosEmpresa();
  },
  components: {
    loading,
    'erro-conexao': erroConexao,
  },
};
</script>

<style lang="scss">
#opt-ini-whatsapp {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  .content {
    border-radius: 8px;
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
      width: 64px;
    }

    h1 {
      font-size: 24px;
      margin: 16px 0;
    }

    p {
      margin-bottom: 12px;
      line-height: 20px;
    }
  }

  a.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: #128c7e !important;
    color: white !important;
    font-size: 14px;
    padding: 12px;
  }

  a.btn:hover,
  a.btn:active {
    background-color: #075e54 !important;
  }

  .recusa {
    font-size: 12px;
  }
}

body {
  background-image: url('./../../assets/images/1.png');
}
</style>
